<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.announcement.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton color="primary" v-on:click="createTableRow" style="margin-right: 10px;"
                         v-if="checkPermission('create-announcement')">
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'Announcement')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert v-show="alertMessage" :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
            {{ alertMessage }}
          </CAlert>
          <v-server-table :columns="columns" url="/announcements/list" :options="options" ref="myTable"
                          @loaded="exports(self,'Excel',module,'Announcement')">
            <template #actions="data">
              <CButton color="warning" v-c-tooltip="$lang.buttons.general.crud.view"
                       v-on:click="viewTableRow(data.row.id)">
                <i class="fas fa-eye"></i>
              </CButton>
              <CButton color="danger" v-c-tooltip="$lang.buttons.general.crud.delete"
                       v-on:click="deletePopup(data.row.id)" v-if="checkPermission('delete-announcement')">
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {announcement} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from 'vue-tables-2';
import VueJsonToCsv from "vue-json-to-csv";
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false)
Vue.use(VueForm, options);
export default {
  name: 'AnnouncementItem',
  mixins: [Mixin],
  components: {
    VueJsonToCsv
  },
  data() {
    return {
      dismissCountDown: 0,
      alertMessage: '',
      messageColor: 'success',
      self: this,
      json_data: [],
      json_label: {},
      json_title: "",
      columns: ['type', 'message','appType', 'isMaintenance', 'actions'],
      data: [],
      options: {
        headings: {
          type: this.$lang.announcement.table.type,
          appType: this.$lang.announcement.table.appType,
          message: this.$lang.announcement.table.message,
          isMaintenance: this.$lang.announcement.table.isMaintenance,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ['type'],
        sortable: ['type'],
        filterable: ['type']
      },
      module: announcement,
    }
  },
  created() {
    let self = this;
    if (localStorage.getItem('notificationMessage') !== "") {
      self.dismissCountDown = 10;
      self.alertMessage = localStorage.getItem('notificationMessage');
      self.messageColor = localStorage.getItem('notificationType');
      localStorage.setItem('notificationMessage', '');
      localStorage.setItem('notificationType', '');
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
  },
  methods: {
    viewTableRow(item) {
      this.$router.push({name: 'View Announcement', params: {id: item}})
    },
    createTableRow() {
      this.$router.push({name: 'Create Announcement'})
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios.delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDown = 10;
                  self.messageColor = "success";
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
  }
}
</script>
